import Vue from 'vue';
import VueRouter from 'vue-router';

// Insights client
import { trackers } from '@/plugins/insights';

import StartPage from '@/views/StartPage.vue';

// Onboarding Login
import OnboardingMainPage from '@/views/OnboardingLogin/OnboardingMainPage.vue';
import Onboarding from '@/views/OnboardingLogin/Onboarding.vue';
import OnboardingBemVindoPage from '@/views/OnboardingLogin/OnboardingBemVindoPage.vue';
import OnboardingSimuladosPage from '@/views/OnboardingLogin/OnboardingSimuladosPage.vue';
import OnboardingAprendaMaisPage from '@/views/OnboardingLogin/OnboardingAprendaMaisPage.vue';

// Login
import TipoUsuarioPage from '@/views/OnboardingLogin/TipoUsuarioPage.vue';
import LoginInclude from '@/views/OnboardingLogin/LoginInclude.vue';
import ExternalLoginInclude from '@/views/OnboardingLogin/ExternalLoginInclude.vue';
import PesquisarLocalizacao from '@/views/OnboardingLogin/PesquisarLocalizacao.vue';


// N�o cadastrado
import NaoCadastrado from '@/views/OnboardingLogin/NaoCadastrado.vue';
import RecuperarSenha from '@/views/OnboardingLogin/RecuperarSenha.vue';
import OnboardingMap from '@/views/OnboardingLogin/OnboardingMap.vue';

// Criar conta
import CriarContaMainPage from '@/views/CriarConta/CriarContaMainPage.vue';
import CriarContaInicio from '@/views/CriarConta/CriarContaInicio.vue';
import CriarContaChat from '@/views/CriarConta/CriarContaChat.vue';
import CriarContaTermos from '@/views/CriarConta/CriarContaTermos.vue';

// Main
import MainPage from '@/views/Main/MainPage.vue';
import TabsPage from '@/views/Main/TabsPage.vue';

// Home
import HomePage from '@/views/Main/Home/HomePage.vue';
import SimuladosPage from '@/views/Main/Simulados/SimuladosPage.vue';
import AulasMainPage from '@/views/Main/Aulas/AulasMainPage.vue';
import AulasConcluidas from '@/views/Main/Aulas/AulasConcluidas.vue';
import AulasAvaliadas from '@/views/Main/Aulas/AulasAvaliadas.vue';
import AulasAgendadas from '@/views/Main/Aulas/AulasAgendadas.vue';

//Notifica��es
import NotificationsListPage from '@/modules/core/views/notifications/NotificationsListPage.vue';


// Detalhe aula
import DetalheAulaMainPage from '@/views/DetalheAula/DetalheAulaMainPage.vue';

// Onboarding Pratico
import OnboardingPraticoMainPage from '@/views/OnboardingPratico/OnboardingPraticoMainPage.vue';
import OnboardingPraticoUm from '@/views/OnboardingPratico/OnboardingPraticoUm.vue';
import OnboardingPraticoDois from '@/views/OnboardingPratico/OnboardingPraticoDois.vue';
import OnboardingPraticoTres from '@/views/OnboardingPratico/OnboardingPraticoTres.vue';
import OnboardingPraticoQuatro from '@/views/OnboardingPratico/OnboardingPraticoQuatro.vue';

// Onboarding aulas
const OnboardingAulas = () => import(/* webpackChunkName: "onboarding-aulas" */ '@/views/OnboardingAulas/OnboardingAulas.vue');

// Auto escolas
const AutoescolasPage =  () => import(/* webpackChunkName: "autoescolas" */ '@/views/Main/Autoescolas/AutoescolasPage.vue');
const DetalheAutoescola = () => import(/* webpackChunkName: "autoescolas" */ '@/views/Main/Autoescolas/DetalheAutoescola.vue');
const FiltroAutoescolasPage = () => import(/* webpackChunkName: "autoescolas" */ '@/views/Main/Autoescolas/FiltroAutoescolasPage.vue');

// Simulado Teorico
const SimuladoTeoricoMainPage = () => import(/* webpackChunkName: "simulado-teorico" */ '@/views/SimuladoTeorico/SimuladoTeoricoMainPage.vue');
const SimuladoTeoricoHome = () => import(/* webpackChunkName: "simulado-teorico" */ '@/views/SimuladoTeorico/SimuladoTeoricoHome.vue');
const CategoriaSimulado = () => import(/* webpackChunkName: "simulado-teorico" */ '@/views/SimuladoTeorico/CategoriaSimulado.vue');
const QuestoesTeoricoPage = () => import(/* webpackChunkName: "simulado-teorico" */ '@/views/QuestoesTeorico/QuestoesTeoricoPage.vue');
const ResultadoTeoricoPage = () => import(/* webpackChunkName: "simulado-teorico" */ '@/views/ResultadoTeorico/ResultadoTeoricoPage.vue');

// Multiplayer
const MultiplayerMainPage = () => import(/* webpackChunkName: "multiplayer" */ '@/views/Multiplayer/MultiplayerMainPage.vue');
const Countdown = () => import(/* webpackChunkName: "multiplayer" */ '@/views/Multiplayer/Countdown.vue');
const MultiplayerQuestion =  () => import(/* webpackChunkName: "multiplayer" */ '@/views/Multiplayer/MultiplayerQuestion.vue');
const ResultPage =  () => import(/* webpackChunkName: "multiplayer" */ '@/views/Multiplayer/ResultPage.vue');
const RankingPage =  () => import(/* webpackChunkName: "multiplayer" */ '@/views/Multiplayer/RankingPage.vue');

// Versus
const VersusPage =  () => import(/* webpackChunkName: "versus" */ '@/views/Versus/VersusMainPage.vue');
const BonusPage =  () => import(/* webpackChunkName: "versus" */ '@/views/Versus/BonusPage.vue');
const ThemePage =  () => import(/* webpackChunkName: "versus" */ '@/views/Versus/ThemePage.vue');

// Menu
const MenuMainPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/MenuMainPage.vue');
const ConfiguracoesPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/ConfiguracoesPage.vue');
const ContaPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/ContaPage.vue');
const ExcluirContaPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/ExcluirContaPage.vue');
const ExcluirContaFinalPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/ExcluirContaFinalPage.vue');
const ConvidarAmigoPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/ConvidarAmigoPage.vue');
const TermosPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/TermosPage.vue');
const RedesSociaisPage = () => import(/* webpackChunkName: "menu" */ '@/views/Menu/RedesSociaisPage.vue');

// Meus resultados
const MeusResultadosPage = () => import(/* webpackChunkName: "resultados" */ '@/views/MeusResultados/MeusResultadosPage.vue');
const CategoriaA = () => import(/* webpackChunkName: "resultados" */ '@/views/MeusResultados/CategoriaA.vue');
const CategoriaB = () => import(/* webpackChunkName: "resultados" */ '@/views/MeusResultados/CategoriaB.vue');
const Disciplinas = () => import(/* webpackChunkName: "resultados" */ '@/views/MeusResultados/Disciplinas.vue');

//Agendamento
import NewSchedulingPage from '@/modules/scheduling/views/NewSchedulingPage.vue';
import NewSchedulingCategoryPage from '@/modules/scheduling/views/NewSchedulingCategoryPage.vue';
import NewSchedulingDatePage from '@/modules/scheduling/views/NewSchedulingDatePage.vue';
import NewSchedulingTeacherPage from '@/modules/scheduling/views/NewSchedulingTeacherPage.vue';
import NewSchedulingSlotPage from '@/modules/scheduling/views/NewSchedulingSlotPage.vue';
import NewSchedulingPickupLocationPage from '@/modules/scheduling/views/NewSchedulingPickupLocationPage.vue';
import NewSchedulingConfirmationPage from '@/modules/scheduling/views/NewSchedulingConfirmationPage.vue';
import NewSchedulingRulesInfoPage from '@/modules/scheduling/views/NewSchedulingRulesInfoPage.vue';
import NewSchedulingDonePage from '@/modules/scheduling/views/NewSchedulingDonePage.vue';
import SchedulingView from '@/modules/scheduling/views/SchedulingView.vue';
import SchedulingViewCancelConfirmation from '@/modules/scheduling/views/SchedulingViewCancelConfirmation.vue';

import AddCreditsPage from '@/modules/core/components/AddCredits/AddCreditsPage.vue';

// Simulado Pratico
import SimuladoPraticoMainPage from '@/views/SimuladoPratico/SimuladoPraticoMainPage.vue';
import SelecionarCategoriaPage from '@/views/SimuladoPratico/SelecionarCategoriaPage.vue';
import CategoriaDetalhe from '@/views/SimuladoPratico/CategoriaDetalhe.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'start',
            component: StartPage
        },
        {
            path: '/external-login',
            name: 'external-login',
            component: ExternalLoginInclude
        },
        {
            path: '/onboarding',
            component: OnboardingMainPage,
            children: [
                {
                    path: '',
                    component: Onboarding,
                    children: [
                        {
                            path: '',
                            name: 'onboardingbemvindo',
                            component: OnboardingBemVindoPage
                        },
                        {
                            path: 'onboardingsimulados',
                            name: 'onboardingsimulados',
                            component: OnboardingSimuladosPage
                        },
                        {
                            path: 'onboardingaprendamais',
                            name: 'onboardingaprendamais',
                            component: OnboardingAprendaMaisPage
                        },
                        {
                            path: 'tipousuario',
                            component: TipoUsuarioPage,
                            children: [
                                {
                                    path: 'login',
                                    name: 'login',
                                    component: LoginInclude
                                },
                                {
                                    path: 'localizacao',
                                    name: 'localizacao',
                                    component: PesquisarLocalizacao
                                },
                                {
                                    path: 'pesquisarlocalizacao',
                                    name: 'pesquisarlocalizacao',
                                    component: PesquisarLocalizacao
                                },
                            ]
                        },
                    ]
                },
                {
                    path: 'naocadastrado',
                    name: 'naocadastrado',
                    component: NaoCadastrado
                },
                {
                    path: 'recsenha',
                    name: 'recsenha',
                    component: RecuperarSenha
                },
                {
                    path: 'onboardingmap',
                    name: 'onboardingmap',
                    component: OnboardingMap
                },
                {
                    path: 'buscarmapa',
                    name: 'buscarmapa',
                    component: OnboardingMap
                },
                
            ]
        },
        {
            path: '/criarconta',
            component: CriarContaMainPage,
            children: [
                {
                    path: 'inicio',
                    name: 'containicio',
                    component:CriarContaInicio
                },
                {
                    path: 'chat',
                    name: 'contachat',
                    component:CriarContaChat
                },
                {
                    path: 'termos',
                    name: 'termos',
                    component: CriarContaTermos
                }
            ]
        },
        {
            path: '/detalhe/:id',
            component: DetalheAulaMainPage,
            children: [
                {
                    path: '',
                    name: 'auladetalhe'
                }
            ]
        },
        {
            path: '/new-scheduling-rules-info',
            name: 'new-scheduling-rules-info',
            component: NewSchedulingRulesInfoPage,
        },
        {
            path: '/add-credits',
            name: 'add-credits',
            component: AddCreditsPage,
        },        
        {
            path: '/new-scheduling',
            component: NewSchedulingPage,
            children: [
                {
                    path: '/new-scheduling/category',
                    name: 'new-scheduling-category',
                    component: NewSchedulingCategoryPage,
                },
                {
                    path: '/new-scheduling/date',
                    name: 'new-scheduling-date',
                    component: NewSchedulingDatePage,
                },
                {
                    path: '/new-scheduling/teacher',
                    name: 'new-scheduling-teacher',
                    component: NewSchedulingTeacherPage,
                },
                {
                    path: '/new-scheduling/slot',
                    name: 'new-scheduling-slot',
                    component: NewSchedulingSlotPage,
                },
                {
                    path: '/new-scheduling/pickup-location',
                    name: 'new-scheduling-pickup-location',
                    component: NewSchedulingPickupLocationPage,
                },
                {
                    path: '/new-scheduling/confirmation',
                    name: 'new-scheduling-confirmation',
                    component: NewSchedulingConfirmationPage,
                },
                {
                    path: '/new-scheduling/done',
                    name: 'new-scheduling-done',
                    component: NewSchedulingDonePage,
                }
            ]
        },
        {
            path: '/onboardingaulas',
            name: 'onboardingaulas',
            beforeEnter: (to, from, next) => {
                trackers.trackPage(to.name!, to.fullPath);
                next();
            },
            component: OnboardingAulas
        },
        {
            path: '/onboardingpratico',
            component: OnboardingPraticoMainPage,
            children: [
                {
                    path: '',
                    name: 'onboarding-pratico-1',
                    meta: { index: 1 },
                    component: OnboardingPraticoUm
                },
                {
                    path: '2',
                    name: 'onboarding-pratico-2',
                    meta: { index: 2 },
                    component: OnboardingPraticoDois
                },
                {
                    path: '3',
                    meta: { index: 3 },
                    name: 'onboarding-pratico-3',
                    component: OnboardingPraticoTres
                },
                {
                    path: '4',
                    meta: { index: 4 },
                    name: 'onboarding-pratico-4',
                    component: OnboardingPraticoQuatro
                },
            ]
        },
        {
            path: '/questoesteorico',
            beforeEnter: (to, from, next) => from.name == 'categoria-teorico' ? next() : next({ name:'categoria-teorico' }),
            name: 'questoes-teorico',
            component: QuestoesTeoricoPage,
        },
        {
            path: '/conferenciateorico',
            name: 'conferencia-teorico',
            component: QuestoesTeoricoPage,
        },
        {
            path: '/resultadoteorico',
            name: 'resultado-teorico',
            component: ResultadoTeoricoPage,
        },
        // =================== CFCs ===============
        {
            path: '/filtro-autoescolas',
            name: 'filtro-autoescolas',
            component: FiltroAutoescolasPage
        },
        {
            path: '/detalhe-autoescola',
            name: 'detalhe-autoescola',
            component: DetalheAutoescola
        },

        // =================== Main ================
        {
            path: '/main',
            component: MainPage,
            children: [
                {
                    path: 'home',
                    name: 'home',
                    component: HomePage,
                },
                {
                    path: 'autoescolas',
                    component: TabsPage,
                    children: [
                        {
                            path: '',
                            name: 'autoescolas',
                            meta: { title: 'Autoescolas' },
                            component: AutoescolasPage
                        }
                    ]
                },
                {
                    path: 'notificacoes',
                    name: 'notificacoes',
                    meta: { title: 'Notifica��es' },
                    component: NotificationsListPage,
                },
                {
                    path: 'simulados',
                    component: TabsPage,
                    children: [
                        {
                            path: '',
                            name: 'simulados',
                            meta: { title: 'Simulados' },
                            beforeEnter: (to, from, next) => {
                                trackers.trackPage(to.name!, to.fullPath);
                                next();
                            },
                            component: SimuladosPage
                        }
                    ]
                },
                {
                    path: 'aulas',
                    component: TabsPage,
                    children: [
                        {
                            path: '',
                            component: AulasMainPage,
                            beforeEnter: (to, from, next) => {
                                trackers.trackPage(to.name!, to.fullPath);
                                next();
                            },
                            children: [
                                {
                                    path: 'concluidas',
                                    name: 'concluidas',
                                    meta: { title: 'Minhas aulas' },
                                    component: AulasConcluidas
                                },
                                {
                                    path: 'avaliadas',
                                    name: 'avaliadas',
                                    meta: { title: 'Minhas aulas' },
                                    component: AulasAvaliadas
                                },
                                {
                                    path: '',
                                    name: 'aulas',
                                    meta: { title: 'Minhas aulas' },
                                    component: AulasAgendadas
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        // =============== MENU =====================
        {
            path: '/menu',
            name: 'menu',
            component: MenuMainPage,
        },
        {
            path: '/aulas/agendadas/:id',
            name: 'scheduling-details',
            meta: { title: 'Aula agendada' },
            component: SchedulingView
        },        
        {
            path: '/aulas/agendadas/:id',
            name: 'scheduling-cancel-confirmation',
            meta: { title: 'Cancelamento de agendamento' },
            component: SchedulingViewCancelConfirmation
        },        
        {
            path: '/account',
            name: 'account',
            component: ContaPage
        },
        {
            path: '/delete-account',
            name: 'delete-account',
            component: ExcluirContaPage
        },
        {
            path: '/delete-account-final',
            name: 'delete-account-final',
            component: ExcluirContaFinalPage
        },
        {
            path: '/settings',
            name: 'settings',
            meta: {
                title: 'Configura��es'  
            },
            component: ConfiguracoesPage
        },
        {
            path: '/terms',
            name: 'terms',
            meta: {
                title: 'Termos de privacidade'  
            },
            component: TermosPage
        },
        {
            path: '/invite',
            name: 'invite',
            meta: {
                title: 'Convidar amigo'  
            },
            component: ConvidarAmigoPage
        },
        {
            path: '/socialmedias',
            name: 'social-medias',
            meta: {
                title: 'Redes sociais'  
            },
            component: RedesSociaisPage
        },

        // =============== MEUS RESULTADOS =================
        {
            path: '/resultados',
            component: MeusResultadosPage,
            beforeEnter: (to, from, next) => {
                trackers.trackPage(to.name!, to.fullPath);
                next();
            },
            children: [
                {
                    path: '',
                    name: 'disciplinas',
                    component: Disciplinas
                },
                {
                    path: 'categoria-a',
                    name: 'categoria-a',
                    component: CategoriaA
                },
                {
                    path: 'categoria-b',
                    name: 'categoria-b',
                    component: CategoriaB
                }
            ]
        },
        {
            path: '/simuladopratico',
            component: SimuladoPraticoMainPage,
            children: [
                {
                    path: '',
                    name: 'simuladopratico',
                    component: SelecionarCategoriaPage
                },
                {
                    path: 'detalhe',
                    name: 'patricoDetalhe',
                    component: CategoriaDetalhe
                }
            ]
        },
        {
            path: '/simuladoteorico',
            component: SimuladoTeoricoMainPage,
            children: [
                {
                    path: '',
                    name: 'simulado-teorico',
                    component: SimuladoTeoricoHome
                },
                {
                    path: 'categoria',
                    name: 'categoria-teorico',
                    component: CategoriaSimulado
                },
            ]
        },
        // ============== MULTIPLAYER ==================
        {
            path: '/multiplayer',
            component: MultiplayerMainPage,
            name: 'multiplayer'
        },
        {
            path: '/countdown',
            name: 'countdown',
            component: Countdown,
        },
        {
            path: '/multiplayer-question',
            name: 'multiplayer-question',
            component: MultiplayerQuestion
        },
        {
            path: '/multiplayer-result',
            name: 'multiplayer-result',
            component: ResultPage
        },
        {
            path: '/multiplayer-ranking',
            name: 'multiplayer-ranking',
            component: RankingPage
        },
        // ============ VERSUS ==============
        {
            path: '/versus',
            name: 'versus',
            component: VersusPage
        },
        {
            path: '/countdownversus',
            name: 'countdown-versus',
            meta: {
                carro: true,
            },
            component: Countdown,
        },
        {
            path: '/theme',
            name: 'theme',
            component: ThemePage,
        },
        {
            path: '/bonus',
            name: 'bonus',
            component: BonusPage
        }
    ]
});

export default router;
